<template>
	<div id="app">
		<router-view/>
	</div>
</template>
<script>

	import AOS from "aos";

	export default {
		name: "home",
		data: () => {
			return {}
		},
		mounted() {
			AOS.init();
			console.log("123")
		}
	}

</script>

<style lang="scss">
	* {
		padding: 0;
		margin: 0;
		font-family: PingFangSC-Regular, PingFang SC;
		box-sizing: border-box;
		color: #222;

	}


</style>
