<template>
  <div id="home">

    <div id="header" class="header">

      <div id="logo" class="logo">
        <img src="@/assets/logo.png" class="logo">
      </div>

      <div class="menu">

        <!--        <div class="menu-item">-->
        <!--          <div class="title">-->
        <!--            <a href="http://www.ubiboat.com/doc/summary/">开发支持</a>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="menu-submenu">
          <div class="title">
            开发支持
            <img src="@/assets/icon-down.png" class="icon-down iconfont"/>
            <img src="@/assets/icon-top.png" class="icon-top iconfont"/>
          </div>
          <div class="panel">
            <div class="item">
              <!--              <svg class="icon" aria-hidden="true">-->
              <!--                <use xlink:href="#icon-agora_kaifazhe"></use>-->
              <!--              </svg>-->
              <div class="menu-route">
                <!--                <a href="http://www.ubiboat.com/doc/summary/">开发支持</a>-->
                <a href="http://www.ubiboat.com/doc/summary/" class="title">
                  Android SDK
                </a>

              </div>
            </div>

            <div class="item">
              <!--              <svg class="icon" aria-hidden="true">-->
              <!--                <use xlink:href="#icon-agora_kaifazhe"></use>-->
              <!--              </svg>-->
              <div class="menu-route">
                <!--                <a href="http://www.ubiboat.com/doc/summary/">开发支持</a>-->
                <a href="http://www.ubiboat.com/doc/iOS/guide.html" class="title">
                  iOS SDK
                </a>

              </div>
            </div>


            <div class="item">
              <!--              <svg class="icon" aria-hidden="true">-->
              <!--                <use xlink:href="#icon-agora_anzhuangSDK"></use>-->
              <!--              </svg>-->
              <div class="menu-route">
                <a href="http://www.ubiboat.com/doc/web/guide.html" class="title">
                  Web 服务
                </a>
              </div>
            </div>

          </div>
        </div>

        <div class="menu-item">
          <div class="title">
            <a href="http://www.ubiboat.com/doc/faq/account.html">FAQ</a>
          </div>
        </div>

      </div>

      <div id="login-button" class="button">

        <a href="http://www.ubiboat.com/api/#/account">注册/登陆</a>


      </div>
      <span >
          咨询电话：010-53517711, 13811456265
      </span>


    </div>
    <main>
      <div class="banner">
        <el-carousel trigger="click" :interval="3000" height="31.25vw">
          <el-carousel-item>
            <!--            <img src="@/assets/banner-1.jpg"> -->
            <img src="@/assets/bg1.jpg">

          </el-carousel-item>

          <!--          <el-carousel-item>-->
          <!--            <div class="banner-video">-->
          <!--              <div class="left">-->
          <!--                <div class="banner-video-title">实时互动在毫秒间</div>-->
          <!--                <div class="banner-video-title-english">-->
          <!--                  The Real-Time Engagement Platform for meaningful human connections-->
          <!--                </div>-->
          <!--                <div class="banner-video-content">-->
          <!--                  四行代码，30分钟，在应用内构建-->
          <!--                  <br>-->
          <!--                  语音通话、视频通话、互动直播、实时消息-->
          <!--                </div>-->
          <!--                <div class="banner-video-button">-->
          <!--                  <div class="">立即注册</div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <video src="@/assets/video-1.mp4" class="homepage-banner-old" autoplay="autoplay"-->
          <!--                     muted="muted" loop="loop"/>-->
          <!--            </div>-->
          <!--          </el-carousel-item>-->

          <el-carousel-item>
            <!--            <img src="@/assets/banner-2.jpg">-->
            <img src="@/assets/bg2.jpg">
          </el-carousel-item>
          <el-carousel-item>
            <!--            <img src="@/assets/banner-3.jpg">-->
            <img src="@/assets/bg3.jpg">
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="section-title">
        <div class="why-powerlbs-title">WHY UbiBoat</div>
        <div class="why-powerlbs-title-2">产品优势</div>
        <div class="why-powerlbs-sub-title">
          PRODUCT SUPERIORITY
        </div>
      </div>

      <div class="scene">
        <div data-aos="fade-up" class="scene-item ">
          <div class="title">核心专利，多年积淀</div>

          <!--          <img src="@/assets/voice-hover.gif" alt="快速集成" class="gif">-->
          <img src="@/assets/1.png" alt="快速集成" class="gif">
        </div>

        <div data-aos="fade-up" class="scene-item">
          <div class="title">无需地图，尽享便利</div>
          <img src="@/assets/2.png" alt="无需地图" class="gif">
          <!--          <img src="@/assets/video-hover.gif" alt="无需地图" class="gif">-->
        </div>

        <div data-aos="fade-up" class="scene-item ">
          <div class="title">无需设备，环境感知</div>
          <img src="@/assets/3.png" class="gif" alt="无需硬件投入">
          <!--          <img src="@/assets/interactive-live-streaming-hover.gif" class="gif" alt="无需硬件投入">-->
        </div>

        <div data-aos="fade-up" class="scene-item">
          <div class="title">定位精准，近在咫尺</div>
          <img src="@/assets/4.png" class="gif" alt="室内外近场感知">
          <!--          <img src="@/assets/interactive-live-streaming-standard.gif" class="gif" alt="室内外近场感知">-->
        </div>

        <div data-aos="fade-up" class="scene-item">
          <div class="title">极简开发，即时上线</div>
          <img src="@/assets/5.png" class="gif" alt="灵活应用">

        </div>


      </div>

      <div class="service">

        <div class="why-powerlbs-title-2">赋能任何场景的实时位置服务</div>
        <div class="why-powerlbs-sub-title">
          Real-Time Location Platform as a Service
        </div>
        <div class="service-wrapper">
          <div data-aos="fade-up" class="service-item">
            <!--            <img src="@/assets/homepage-world-icon.png">-->
            <img src="@/assets/17.png">
            <div>
              <div class="title">元宇宙、数字孪生</div>
              <div class="description">为虚、实空间提供全域位置映射服务，方便融合空间的信息即时获取
              </div>
            </div>
          </div>
          <div data-aos="fade-up" class="service-item">
            <img src="@/assets/16.png">
            <!--            <img src="@/assets/homepage-key-icon.png">-->
            <div>
              <div class="title">全民防疫</div>
              <div class="description">通过智能手机全员测量接触距离，实现精准防控，科学防控
              </div>
            </div>
          </div>
          <div data-aos="fade-up" class="service-item">
            <img src="@/assets/13.png">
            <!--            <img src="@/assets/homepage-controls-icon.png">-->
            <div>
              <div class="title">数字营销</div>
              <div class="description">店铺随时发布、自主营销，店内外相关用户精准接收所需信息
              </div>
            </div>
          </div>
          <div data-aos="fade-up" class="service-item">
            <img src="@/assets/14.png">
            <!--            <img src="@/assets/homepage-blocks-icon.png">-->
            <div>
              <div class="title">近场社交</div>
              <div class="description">为近场范围内的社交提供距离新维度，在同一场景下（会场、展馆、车厢等）不加好友也可畅通交流、传送文件
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="service-item">
            <img src="@/assets/15.png">
            <!--            <img src="@/assets/homepage-blocks-icon.png">-->
            <div>
              <div class="title">智能硬件</div>
              <div class="description">为手机、手表、手环、防丢器等智能终端提供真正的室内防丢、近场搜寻功能
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="service-item">
            <img src="@/assets/18.png">
            <!--            <img src="@/assets/homepage-blocks-icon.png">-->
            <div>
              <div class="title">信息围栏</div>
              <div class="description">为信息传播设定区域和围栏，打造新一代信息传播模式
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="step">

        <div data-aos="fade-up" class="section-title">
          <div class="why-powerlbs-title-2">接入流程</div>
          <div class="why-powerlbs-sub-title">
            ACCESS PROCESS
          </div>
        </div>

        <div data-aos="fade-up" class="step-wrapper">

          <div class="step-item">
            <div>1</div>
            <b>注册账号</b>
          </div>
          <div class="hr">
            <hr>
          </div>

          <div class="step-item">
            <div>2</div>
            <b>创建应用</b>
          </div>

          <div class="hr">
            <hr>
          </div>

          <div class="step-item">
            <div>3</div>
            <b>获取API KEY</b>

          </div>

          <div class="hr">
            <hr>
          </div>
          <div class="step-item">
            <div>4</div>
            <b>SDK 下载</b>

          </div>

          <div class="hr">
            <hr>
          </div>
          <div class="step-item">
            <div>5</div>
            <b>集成泛舟</b>

          </div>

          <div class="hr">
            <hr>
          </div>
          <div class="step-item">
            <div>6</div>
            <b>应用体验</b>
          </div>


        </div>

        <div class="button-more" @click="signup">
          立即体验
        </div>

      </div>


    </main>

    <div class="policy">

      中科劲点（北京）科技有限公司 版权所有 地址：北京市海淀区中关村创业大街昊海楼2层


      <a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备15047372号-2</a>

      京公网安备 11010802035261号

    </div>

  </div>
</template>
<script>

import AOS from "aos";

export default {
  name: "home",
  data: () => {
    return {}
  },
  mounted() {
    AOS.init();
    console.log("123")
  },
  methods: {
    signup: function () {

      window.location.href = "http://www.ubiboat.com/api/#/account";

    }
  }
}

</script>

<style scoped lang="scss">


.policy {
  margin: 0 auto;
  text-align: center;
  height: 5.3125vw;
  background-color: #000522;
  line-height: 5.3125vw;
  color: #fff;
  font-size: 0.833vw;
}

.policy a:link {
  color: #fff;
}

.policy a:visited {
  color: #fff;
}

.policy a:hover {
  color: #fff;
}

.policy a:active {
  color: #fff;
}


.step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5vw;
}

.step-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vw;
}

.step-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.step-item div {
  color: #fff;
  text-align: center;
  display: block;
  // background: url("../assets/square.png") no-repeat center 0;
  background: url("../assets/10.png") no-repeat center 0;
  background-size: auto 100%;
  font-family: PingFangSC-Semibold;
  width: 5vw;
  height: 5vw;
  line-height: 5vw;
}

.step-item b {
  font-weight: 400;
  font-size: 0.93333vw;
  color: #222325;
  display: block;
  width: 8vw;
  text-align: center;
  font-family: PingFangSC-Light;
  margin-top: 2vw;
}

hr {
  width: 6vw;
  background: linear-gradient(90deg, #e0e6f2, #262e46);
  display: block;
  unicode-bidi: isolate;
  overflow: hidden;
  border: 0;
  border-top: 0.1vw solid #eee;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-top: -2vw;
}

.button-more {
  margin-top: 5.125vw;
  text-align: center;
  display: block;
  height: 2.60417vw;
  width: 10vw;
  line-height: 2.60417vw;
  border-radius: 1.51042vw;
  border: 1px solid #099dfd;
  color: #099dfd;
  font-size: .99333vw;
  padding: 0 1.5625vw;
  cursor: pointer;
}


.secure a {
  text-decoration: underline;
  color: #5c5f61;
  font-family: PingFangSC-Light, PingFang SC;
  margin-top: 1.04167vw;
  font-size: .83333vw;
  cursor: pointer;
}

.secure .wrapper .wrapper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 3vw;
  padding-right: 3vw;
}

.secure .wrapper .wrapper-item .name {
  color: #222222;
  font-size: .83333vw;
}

.secure .wrapper .wrapper-item img {
  width: 6.77083vw;
  height: 6.77083vw;
  margin-top: 1.04167vw;

}

.secure .wrapper {
  justify-content: center;
  width: 100%;
  margin-top: 3.125vw;
  display: flex;

}

.secure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.29167vw 19.79167vw 4.16667vw;
  background: #fafafa;
}

.service-wrapper .service-item {
  display: flex;
  align-items: center;
  margin-bottom: 2.08333vw;
  width: 23.38542vw;
}

.service-item img {
  width: 4.01042vw;
  margin-right: .83333vw;
}

.service-item .title {
  font-size: 1.04167vw;
}

.service-item .description {
  font-family: PingFangSC-Light, PingFang SC;
  font-size: .83333vw;
  line-height: 1.35417vw;
  opacity: .8;
}

.service .why-powerlbs-title {
  font-size: .625vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #099dfd;
  line-height: 1.4167;
}

.service .why-powerlbs-title-2 {
  font-size: 1.77083vw;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 1.4118;
  margin-top: .72917vw;
}


.service .why-powerlbs-sub-title {
  font-size: 1.04167vw;
  font-family: PingFangSC-Light, PingFang SC;
  line-height: 1.4;
  margin-top: .52083vw;
}

.service .service-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2.08333vw;
}


.wrapper-item span {
  color: #ffffff;
  font-size: 1.5vw;
}

.wrapper-item .time {
  font-size: .83333vw;
  margin-top: .52083vw;
  margin-bottom: .26042vw;
  color: #ffffff;
}

.wrapper-item .icon {
  font-size: 3vw;

}

.wrapper-item .name {
  color: #ffffff;
}

.scene {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 5.15625vw auto 0;
  position: relative;
  z-index: 99;
}

.scene-item {
  width: 11.66667vw;
  height: 14.21875vw;
  border-radius: .52083vw;
  border: 1px solid #e5f3fc;
  box-shadow: -1px 0.20833vw 0.98958vw -0.15625vw rgb(26 134 192 / 16%);
  padding: 1.04167vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.04167vw;
  background: #fff;
  margin-right: 1.0vw;
  margin-left: 1.0vw;

}

.scene .scene-item .title {
  color: #099dfd;
  font-size: 1.04167vw;
  margin-bottom: 1.04167vw;
}

.scene .scene-item .gif {
  width: 5.9375vw;
}

.icon-right {
  margin-top: 2.08333vw;
  width: 0.78125vw;
  cursor: pointer;
}


.section-title {
  margin-top: 4.47917vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.why-powerlbs-title {
  font-size: .625vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #099dfd;
  line-height: 1.4167;
}

.why-powerlbs-title-2 {
  font-size: 1.77083vw;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 1.4118;
  margin-top: .72917vw;
}

.why-powerlbs-sub-title {
  font-size: 1.04167vw;
  font-family: PingFangSC-Light, PingFang SC;
  line-height: 1.4;
  margin-top: .52083vw;
}


.acminpro-bold {

  font-weight: 700;
  font-size: 1.88333vw;
  color: #099dfd;;
  margin-bottom: .52083vw;
}

.banner {
  width: 100%;
  height: 31.25vw;
  margin-top: 4.2vw;

}

.banner img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50%;

}

.banner-video {
  display: flex;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  cursor: pointer;
  position: relative;
}

.banner-video .left {
  position: absolute;
  top: 9vw;
  left: 22vw;
  width: 22vw;
}

.banner-video .left .banner-video-title {
  color: #fff;
  width: 22.76042vw;
  margin-bottom: 1.04167vw;
  font-size: 2.5vw;
  font-weight: 600;
}

.banner-video .left .banner-video-title-english {
  color: #fff;
  opacity: 0.7;
  font-size: 1.14583vw;
  line-height: 1.5625vw;
  font-family: PingFangSC-Light, PingFang SC;
  margin-bottom: 2vw;
}

.banner-video .left .banner-video-content {
  color: #fff;
  font-size: 1.14583vw;
  line-height: 1.5625vw;
  font-family: PingFangSC-Light, PingFang SC;
}

.banner-video .banner-video-button {
  margin-top: 2.08333vw;
  width: 8.4375vw;
  height: 2.60417vw;
  line-height: 2.60417vw;
  text-align: center;
  color: #099dfd;
  background: #fff;
  border-radius: 1.30208vw;
  position: relative;
  z-index: 99;
  cursor: pointer;
  margin-right: 20px;
}

.el-carousel__arrow {
  width: 4.6875vw;
  height: 4.6875vw;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

* {
  padding: 0;
  margin: 0;
  font-family: PingFangSC-Regular, PingFang SC;
  box-sizing: border-box;
  color: #222;

}

/*包含以下四种的链接*/
a {
  text-decoration: none;
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
}

.header {
  height: 4.2vw;
  padding: 0 1.79167vw;
  background: hsla(0, 0%, 100%, .9);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid var(--borderColorBase);

  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.logo {
  width: 20vw;

}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.03333vw;
  height: 100%;
  margin-left: 30vw;

}

.menu-item {
  margin-right: 1.7625vw;
  margin-left: 1.7625vw;
  height: 100%;
  padding: 1.35vw 0;
  cursor: pointer;
  word-break: keep-all;
}

.menu-item:hover div a {
  color: #099dfd;
}

.title {
  display: flex;
  align-items: center;
}


.item:hover {
  color: #099dfd;
  background-color: #f0f9ff;
}

.item {
  display: flex;
  justify-items: center;
  margin-top: 0.45vw;
  margin-bottom: 0.45vw;
  align-items: center;
}

.icon-top {
  width: 1.1vw;
  margin-left: .10417vw;
  display: none;
}

.icon-down {
  width: 1.1vw;
  transition: transform .3s linear;
  margin-left: .10417vw;
}

.item .icon {
  color: #089cfd;
  font-size: 2.5vw;

  padding-left: 0.5vw;
}

.item .menu-route {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.tag-menu-new {
  width: 1.82292vw;
  height: 0.78125vw;
}

.iconfont {
  font-family: "iconfont" !important;
  font-style: normal !important;
}

.button {
  background: #fc0;
  height: 2.60417vw;
  line-height: 2.60417vw;
  color: #000;
  font-size: .83333vw;
  border-radius: 1.45833vw;
  padding: 0 1.5625vw;
  text-align: center;
  cursor: pointer;

}

.menu-submenu {
  // margin-right: 1.7625vw;
  height: 100%;
  padding: 1.35vw 0;
  cursor: pointer;
  word-break: keep-all;
  position: relative;

}

.menu-submenu:hover div.title {
  color: #099dfd;
}

.menu-submenu:hover div.title {
  color: #099dfd;
}

.menu-submenu:hover .panel {
  animation: pop 0.1s;

  display: flex;

}

@keyframes pop {

  from {
    opacity: 0.6;
    top: 2.5vw;
  }
  to {
    opacity: 1;
    top: 2.6vw;
  }
}


.menu-submenu:hover .title .icon-down {
  display: none;
}

.menu-submenu:hover .title .icon-top {
  display: block;
}

.panel {
  width: 9.8vw;
  left: -2.08333vw;

  flex-direction: column;
  background: #fff;
  border-radius: 0.5vw;
  padding: .52083vw;
  z-index: 9999;
  word-break: keep-all;

  box-shadow: 0 17px 30px 0 rgb(4 96 155 / 28%);

  display: none;

  position: absolute;

  font-size: 0.8vw;

}


.item {
  display: flex;
  justify-items: center;
  margin-top: 0.45vw;
  margin-bottom: 0.45vw;
  align-items: center;

}

.icon-top {
  width: 1.1vw;
  margin-left: .10417vw;
  display: none;
}

.icon-down {
  width: 1.1vw;
  transition: transform .3s linear;
  margin-left: .10417vw;
}

.item .icon {
  color: #089cfd;
  font-size: 2.5vw;

  padding-left: 0.5vw;
}

.item .menu-route {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}


</style>
