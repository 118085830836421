import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import AOS from "aos";
import "aos/dist/aos.css";

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'



Vue.use(AOS);

Vue.use(ElementUI);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
