import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Signup from '../views/Signup.vue'
// import Login from '../views/Login.vue'
// import Doc from '../views/Doc.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
     },
    // {
    //     path: '/doc',
    //     name: 'Doc',
    //     component: Doc
    // },
    // {
    //     path: '/signup',
    //     name: 'Signup',
    //     component: Signup
    // },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: Login
    // }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
